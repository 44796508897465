import { HttpClient } from "../HttpClient";
/**
 * * Post Collection
 */
export const PostCollection = async (collectionData) => {
	try {
		const { data } = await HttpClient.post("api/pexcollections", { ...collectionData });
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
